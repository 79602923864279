import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import PrivatePage from '@/views/PrivatePage.vue';
import { getToken } from '@/api'; // Замените на вашу реализацию

const routes = [
  { path: '/', component: HomePage },
  { path: '/private', component: PrivatePage, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = !!getToken(); // Замените на вашу реализацию

  if (requiresAuth && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;

<template>
  <div :class="['info-block', { 'info-block--open': isContentVisible }]">
    <button class="info-block__trigger" @click="toggleContent">
      <img src="@/assets/img/icons/help.svg" alt="">
    </button>
    <div v-if="isContentVisible" class="info-block__block">
      <button class="info-block__close" @click="toggleContent"></button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus';

export default {
  data() {
    return {
      isContentVisible: false
    };
  },
  methods: {
    toggleContent() {
      if (!this.isContentVisible) {
        EventBus.emit('closeAllInfoBlocks');
      }
      this.isContentVisible = !this.isContentVisible;
    },
    closeContent() {
      this.isContentVisible = false;
    }
  },
  mounted() {
    EventBus.on('closeAllInfoBlocks', this.closeContent);
  },
  beforeUnmount() {
    EventBus.off('closeAllInfoBlocks', this.closeContent);
  }
};
</script>

<style scoped>
/* Ваши стили */
</style>
<template>
  <div>
    <div :class="['mobile-nav', { 'is-opened': isMobileNavOpened }]">
      <div class="mobile-nav__header">
        <a href="/" @click="redirectToHomePage" class="mobile-logo">
          <img src="@/assets/img/ui/logo.svg" alt="">
        </a>

        <button @click="toggleMobileNav" class="button mobile-button">
          <img src="@/assets/img/icons/close.svg" alt="">
        </button>
      </div>

      <div class="mobile-nav__list">
        <a href="/#how-register" @click="toggleMobileNav">Как принять участие</a>
        <a href="/#prizes" @click="toggleMobileNav">Призы</a>
        <a href="/#winners" @click="toggleMobileNav">Победители</a>
        <a href="/#faq" @click="toggleMobileNav" >FAQ</a>
        <a v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank" @click="handleRulesButtonClick()">Правила акции</a>
      </div>

      <div class="mobile-nav__footer">
        <button v-if="!user" @click="openLoginPopup" class="button button-gradient">
          <span>Войти</span>
        </button>
        <template v-else>
          <button class="button button-gradient" @click="redirectToPrivatePage">
            <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
          </button>
          <button @click="logout" class="button button-logout"></button>
        </template>
      </div>
    </div>

    <header :class="{ 'is-scrolled': isHeaderScrolled }">
      <div class="container">
        <div class="header">
          <div class="header-logo">
            <a href="/" @click="redirectToHomePage">
              <img src="@/assets/img/ui/logo.svg" alt="">
            </a>
          </div>
          <div class="header-nav">
            <a href="/#how-register">Как принять участие</a>
            <a href="/#prizes">Призы</a>
            <a href="/#winners">Победители</a>
            <a href="/#faq">FAQ</a>
          </div>
          <div class="header-user">
            <button v-if="!user" @click="openLoginPopup" class="button button-gradient">
              <span>Войти</span>
            </button>
            <template v-else>
              <button class="button button-gradient" @click="redirectToPrivatePage">
                <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
              </button>
              <button @click="logout" class="button button-logout"></button>
            </template>
          </div>
          <div class="mobile-menu">
            <button @click="toggleMobileNav" class="button">
              <img src="@/assets/img/icons/mobile-menu.svg" alt="">
            </button>
          </div>
        </div>
      </div>
    </header>

    <section id="main" class="section">
      <img src="@/assets/img/main/hero-main.jpg"
           srcset="@/assets/img/main/hero-main@2x.jpg 2x"
           class="main-bg main-bg--desktop" alt="">

      <img src="@/assets/img/main/hero-main--mobile.jpg"
           srcset="@/assets/img/main/hero-main--mobile@2x.jpg 2x"
           class="main-bg main-bg--mobile" alt="">

      <a href="#" class="header-spar">
        <img src="@/assets/img/ui/spar.svg" alt="">
      </a>

      <div class="container">

        <div class="promo-date">
          {{ promoDates }}
        </div>

        <div class="promo-text-block">
          <h1>
            Выиграйте
            <br>путешествие
            <br>от Jardin!
          </h1>

          <button v-if="!user" @click="handleParticipateButton" class="button button-gradient button-gradient--big">
            <span>Участвовать</span>
          </button>
          <template v-else>
            <button class="button button-gradient button-gradient--big" @click="triggerQrScan">
              <span>Загрузить чек</span>
            </button>
          </template>

          <div class="promo-timer">
            <div class="promo-timer__title">
              {{ timerMessage }}
            </div>
            <div class="promo-timer__count">
              <div class="promo-timer__count__item">
                дней:
                <strong>{{ days }}</strong>
              </div>
              <div class="promo-timer__count__item">
                часов:
                <strong>{{ hours }}</strong>
              </div>
              <div class="promo-timer__count__item">
                минут:
                <strong>{{ minutes }}</strong>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section id="how-register" class="section">
      <div class="container">
        <div class="steps">
          <div class="steps-item">
            <div class="steps-item__number">
              1
            </div>
            <div class="steps-item__title">
              Купите
            </div>
            <div class="steps-item__text">
              любой кофе Jardin c 2 сентября
              <br>по 31 октября 2024 года
              <br>в магазинах «Spar»
            </div>
          </div>
          <div class="steps-item">
            <div class="steps-item__number">
              2
            </div>
            <div class="steps-item__title">
              Зарегистрируйте
            </div>
            <div class="steps-item__text">
              чек на сайте
              <p>
                <button v-if="!user" @click="handleParticipateButton" class="button button-gradient button-gradient--big">
                  <span>Участвовать</span>
                </button>
                <template v-else>
                  <button class="button button-gradient button-gradient--big" @click="triggerQrScan">
                    <span>Загрузить чек</span>
                  </button>
                </template>
              </p>
            </div>
          </div>
          <div class="steps-item">
            <div class="steps-item__number">
              3
            </div>
            <div class="steps-item__title">
              Получите
            </div>
            <div class="steps-item__text">
              гарантированно
              <br>деньги на телефон
            </div>
          </div>
          <div class="steps-item">
            <div class="steps-item__number">
              4
            </div>
            <div class="steps-item__title">
              Участвуйте
            </div>
            <div class="steps-item__text">
              в розыгрыше призов
            </div>
          </div>
        </div>
      </div>
      <img src="@/assets/img/main/bottom-wave.png"
           srcset="@/assets/img/main/bottom-wave@2x.png 2x"
           class="steps-wave steps-wave--desktop" alt="">

      <img src="@/assets/img/main/bottom-wave--mobile.png"
           srcset="@/assets/img/main/bottom-wave--mobile@2x.png 2x"
           class="steps-wave steps-wave--mobile" alt="">
    </section>

    <section id="prizes" class="section">
      <div class="container">
        <div class="section-title">
          Призы
        </div>

        <div class="prizes-list">
          <swiper
              :modules="modules"
              :slides-per-view="1"
              navigation
              :pagination="{ clickable: true }"
              :breakpoints="{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1000,
                }
              }"
          >
            <swiper-slide>
              <div class="prizes-list-item__info">
                <span class="mini-tag">Гарантированный</span>
                <p>Деньги на баланс
                  <br>телефона</p>
                <InfoBlock>
                  <div class="info-block__title">
                    Номинал выпадает случайным образом
                  </div>
                  <div class="info-block__content">
                    <p>20 руб. - 50 шт.</p>
                    <p>30 руб. - 50 шт.</p>
                    <p>50 руб. - 50 шт.</p>
                    <p>70 руб. - 50 шт.</p>
                    <p>90 руб. - 50 шт.</p>
                    <p>100 руб. - 50 шт.</p>
                    <p>300 руб. - 70 шт.</p>
                    <p>500 руб. - 20 шт.</p>
                    <p>1000 руб. - 10 шт.</p>
                  </div>
                </InfoBlock>
              </div>
              <div class="prizes-list-item__media">
                <span class="mini-tag">Гарантированный</span>
                <img src="@/assets/img/prizes/prize-1.png"
                     srcset="@/assets/img/prizes/prize-1@2x.png 2x" alt="">
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="prizes-list-item__info">
                <span class="mini-tag">Еженедельный</span>
                <p>Денежный приз
                  <br>в размере
                  <br>30 000 рублей</p>
              </div>
              <div class="prizes-list-item__media">
                <span class="mini-tag">Еженедельный</span>
                <img src="@/assets/img/prizes/prize-2.png"
                     srcset="@/assets/img/prizes/prize-2@2x.png 2x" alt="">
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="prizes-list-item__info">
                <span class="mini-tag">Специальный</span>
                <p>Подарочный сертификат «DNS» номиналом
                  <br>30 000 рублей*</p>

                <InfoBlock>
                  <div class="info-block__title">
                    Розыгрыш среди тех, кто зарегистрировал 3 или более чека за период акции
                  </div>
                </InfoBlock>

              </div>
              <div class="prizes-list-item__media">
                <span class="mini-tag">Специальный</span>
                <img src="@/assets/img/prizes/prize-3.png"
                     srcset="@/assets/img/prizes/prize-3@2x.png 2x" alt="">
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="prizes-list-item__info">
                <span class="mini-tag mini-tag--main">Главный</span>
                <p>Сертификат
                  <br>в турагентство
                  <br>на  200 000 ₽</p>
              </div>
              <div class="prizes-list-item__media">
                <span class="mini-tag mini-tag--main">Главный</span>
                <img src="@/assets/img/prizes/prize-4.png"
                     srcset="@/assets/img/prizes/prize-4@2x.png 2x" alt="">
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>

    <section id="winners" class="section">
      <div class="container">
        <div class="section-title">
          Победители
        </div>

        <div class="winners-list" v-if="prizes.length">
          <div
              v-for="(prize, index) in prizes"
              :key="index"
              :class="['winners-list-item', getPrizeClass(prize.prize.type)]"
          >
            <div :class="['mini-tag', getPrizeTag(prize.prize.type)]">
              {{ getPrizeTypeText(prize.prize.type) }}
            </div>
            <div class="winners-list-item__title">
              {{ prize.prize.title }}
            </div>
            <div class="winners-list-item__prize">
              <div class="winners-list-item__prize__user">
                <strong>{{ prize.winner.name }}</strong>
                <span>{{ prize.winner.phone_number }}</span>
              </div>
              <img
                  :src="prize.prize.cabinet_image"
                  :alt="prize.prize.title"
                  class="winners-list-item__prize__img"
              />
            </div>
          </div>
        </div>
        <div v-else class="no-winners">
          <div class="no-winners__title">Победители не определены</div>
          <div class="no-winners__btn">
            <button v-if="!user" @click="handleParticipateButton" class="button button-gradient button-gradient--big">
              <span>Участвовать</span>
            </button>
            <template v-else>
              <button class="button button-gradient button-gradient--big" @click="triggerQrScan">
                <span>Загрузить чек</span>
              </button>
            </template>
          </div>
        </div>
      </div>
    </section>

    <section id="faq" class="section">
      <div class="container">
        <div class="section-title">Вопрос - ответ</div>
        <div class="faq-block">
          <div class="faq-block-item" v-for="faq in faq" :key="faq">
            <button class="faq-block-item__btn" @click="toggleAccordion">
              {{ faq.question }}
            </button>
            <div class="faq-block-item__text" v-html="formatText(faq.answer)"></div>
          </div>
        </div>
      </div>
    </section>

    <section id="contacts" class="section">
      <div class="container">
        <div class="section-title">
          Остались вопросы?
        </div>
        <div class="contact-form">
          <form @submit.prevent="submitQuestion" autocomplete="off">
            <div class="field-row" data-cols="2">
              <div class="field-item">
                <label for="feedback-name">Имя</label>
                <input
                    type="text"
                    v-model="questionForm.name"
                    id="feedback-name"
                    name="name"
                    @input="removeSpacesName"
                    :disabled="user && !!user.first_name"
                    :class="{ 'is-error': feedbackErrors.name }"
                    placeholder="например, Иванов Иван" />
                <span v-if="feedbackErrors.name" class="error-message">{{ feedbackErrors.name }}</span>
              </div>
              <div class="field-item">
                <label for="feedback-email">Email</label>
                <input
                    type="text"
                    v-model="questionForm.email"
                    id="feedback-email"
                    name="email"
                    :disabled="user && !!user.email"
                    :class="{ 'is-error': feedbackErrors.email }"
                    placeholder="например, example@mail.ru" />
                <span v-if="feedbackErrors.email" class="error-message">{{ feedbackErrors.email }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-message">Сообщение</label>
                <textarea v-model="questionForm.text"
                          id="feedback-message"
                          name="text"
                          :class="{ 'is-error': feedbackErrors.text }"
                          placeholder="Напишите вопрос, который у вас возник">
                </textarea>
                <span v-if="feedbackErrors.text" class="error-message">{{ feedbackErrors.text }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="feedback-personality">
                  <input type="checkbox" id="feedback-personality" v-model="questionForm.agree_personal_data">
                  <span>Согласен(а) с <a v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank" class="link">политикой конфиденциальности</a></span>
                </label>
                <span v-if="feedbackErrors.agree_personal_data" class="error-message">{{ feedbackErrors.agree_personal_data }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-gradient">
                  <span>Отправить</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>

    <!-- Popup для входа -->
    <div v-if="showLoginPopup" class="popup">
      <div class="popup__overlay" @click="closeLoginPopup"></div>
      <div class="popup__window">
        <button @click="closeLoginPopup" class="popup__close"></button>
        <div class="popup__title">Авторизация</div>
        <div class="popup__content">
          <form @submit.prevent="login" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="login">Логин</label>
                <input type="text" maxlength="18" id="login" v-model="phone_number" name="phone_number" v-mask :class="{ 'is-error': loginFormErrors.phone_number }">
                <span v-if="loginFormErrors.phone_number" class="error-message">{{ loginFormErrors.phone_number }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="password">Пароль</label>
                <input
                    :type="passwordVisible ? 'text' : 'password'"
                    maxlength="255"
                    id="password"
                    v-model="password"
                    name="password"
                />
                <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password }}<br></span>
                <span class="link" @click="openResetPasswordPopup">Забыли пароль?</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-gradient button-gradient--big button-gradient--full">
                  <span>Войти</span>
                </button>
              </div>
            </div>
            <div class="field-row field-row-account">
              <div class="field-item">
                Нет аккаунта?
                <button class="button button-white" @click="openRegisterPopup">
                  <span>Регистрация</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Popup для регистрации -->
    <div v-if="showRegisterPopup" class="popup">
      <div class="popup__overlay"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopup" class="popup__close"></button>
        <div class="popup__title">Регистрация</div>
        <div class="popup__content">
          <form @submit.prevent="register" autocomplete="off">
            <!-- Поле Имя -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-name">Имя</label>
                <input type="text" maxlength="255" id="reg-name" v-model="registerForm.first_name" @input="removeSpacesFirstName" name="first_name"
                       autocomplete="off"
                       @blur="clearError('first_name')"
                       :class="{ 'is-error': errors.first_name }">
                <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
              </div>
            </div>
            <!-- Поле Фамилия -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="last-name">Фамилия</label>
                <input type="text" maxlength="255" id="last-name" v-model="registerForm.last_name" @input="removeSpacesLastName" name="last_name"
                       autocomplete="off"
                       @blur="clearError('last_name')"
                       :class="{ 'is-error': errors.last_name }">
                <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
              </div>
            </div>
            <!-- Поле Номер телефона -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Номер телефона</label>
                <input type="text" maxlength="18" id="reg-phone" v-model="registerForm.phone_number" name="phone_number"
                       autocomplete="off"
                       @blur="clearError('phone_number')"
                       :class="{ 'is-error': errors.phone_number }" v-mask>
                <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
              </div>
            </div>
            <!-- Поле Email -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-email">Email</label>
                <input type="text" maxlength="255" id="reg-email" v-model="registerForm.email" name="email"
                       autocomplete="off"
                       @blur="clearError('email')"
                       :class="{ 'is-error': errors.email }">
                <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с обработкой персональных данных -->
            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-personality">
                  <input type="checkbox" id="reg-personality" v-model="registerForm.agree_personal_data" @blur="clearError('agree_personal_data')">
                  <span>Согласен(а) <a class="link" v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank">с политикой конфиденциальности</a></span>
                </label>
                <span v-if="errors.agree_personal_data" class="error-message">{{ errors.agree_personal_data }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с правилами акции -->
            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-rules">
                  <input type="checkbox" id="reg-rules" v-model="registerForm.agree_rules" @blur="clearError('agree_rules')">
                  <span>Согласен(на) <a class="link" v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank" @click="handleRulesButtonClick()">с правилами акции</a></span>
                </label>
                <span v-if="errors.agree_rules" class="error-message">{{ errors.agree_rules }}</span>
              </div>
            </div>
            <!-- Кнопка для отправки формы регистрации -->
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-gradient button-gradient--big button-gradient--full">
                  <span>Регистрация</span>
                </button>
              </div>
            </div>
            <!-- Кнопка для переключения на окно входа -->
            <div class="field-row field-row-account">
              <div class="field-item">
                Уже есть аккаунт?
                <button class="button button-white" @click="switchToLogin">
                  <span>Войти</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div v-if="showRegisterPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeRegisterPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopupSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Спасибо за регистрацию.
            <br>Пароль для входа отправлен в&nbsp;смс
          </div>
        </div>
      </div>
    </div>

    <div v-if="showQuestionPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeQuestionPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeQuestionPopupSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Ваше обращение принято
            <br>Мы свяжемся с вами в ближайшее время
          </div>
        </div>
      </div>
    </div>

    <!-- Popup для восстановления пароля -->
    <div v-if="showResetPasswordPopup" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopup"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopup" class="popup__close"></button>
        <div class="popup__title">Восстановление пароля</div>
        <div class="popup__content">
          <form @submit.prevent="submitResetPassword" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Номер телефона</label>
                <input type="text" maxlength="18" id="reg-phone" v-model="resetPasswordForm.phone_number" name="phone_number"
                       :class="{ 'is-error': resetPasswordErrors.phone_number }" v-mask>
                <span v-if="resetPasswordErrors.phone_number" class="error-message">{{ resetPasswordErrors.phone_number }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-gradient button-gradient--big button-gradient--full">
                  <span>Отправить</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showResetPasswordSuccess" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopupSuccess" class="popup__close"></button>
        <div class="popup__title">
          Восстановление пароля
        </div>
        <div class="popup__content">
          <div class="success-message">
            Новый пароль был отправлен на номер телефона {{ formattedPhoneNumber }}
          </div>
          <p>
            <button class="button button-gradient button-gradient--big button-gradient--full" @click="switchToLogin">
              <span>Войти</span>
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import InfoBlock from '@/components/infoBlock.vue';
import { fetchFAQ, getToken, getUserInfo, removeToken, authorize, register, getMainSettings, sendFeedback, setAuthHeader, sendResetPassword, getWinnings } from '@/api';
import metrikaMixin from '@/mixins/metrikaMixin';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export default {
  name: 'App',
  mixins: [metrikaMixin],
  components: {
    Swiper,
    SwiperSlide,
    InfoBlock
  },
  setup() {
    return {
      modules: [ Pagination ],
    };
  },
  data() {
    return {
      isHeaderScrolled: false,
      scrollThreshold: 0,
      rootUrl: window.location.origin,
      userId: '',
      userUuid: '',
      isMobileNavOpened: false,
      faq: [],
      prizes: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showResetPasswordPopup: false,
      showResetPasswordSuccess: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      },
      loginForm: {
        phone_number: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      promoDates: '',
      mainSettings: {},
    };
  },
  async created() {
    try {
      this.mainSettings = await getMainSettings();
    } catch (error) {
      console.error('Failed to fetch main settings', error);
    }
    this.loadFAQ();
    this.checkAuthentication();
    this.fetchTimerData();
  },
  mounted() {
    this.fetchPrizes();
    setAuthHeader();
    const token = getToken();
    if (token) {
      setTimeout(async () => {
        getUserInfo().then(userInfo => {
          if (userInfo) {
            this.userId = userInfo.id;
            this.userUuid = userInfo.uuid;
            this.user = {
              first_name: userInfo.first_name,
              last_name_initial: userInfo.last_name.charAt(0),
            };
            this.questionForm.name = userInfo.first_name;
            this.questionForm.email = userInfo.email;
            // this.initQrWidget();
          }
        }).catch(error => {
          console.error('Failed to fetch user info on mount', error);
        });
      }, 900); // 500 мс задержка
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    formattedPhoneNumber() {
      // Убираем знак '+' и все нецифровые символы
      const cleaned = this.phoneNumber.replace(/\D/g, '');

      // Проверяем, что номер начинается с '7' и содержит 11 цифр
      if (cleaned.length === 11 && cleaned.startsWith('7')) {
        return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
      }

      return ' ';
    }
  },
  methods: {
    handleRulesButtonClick() {
      this.$sendMetrik('reachGoal', 'Rules_Click');
    },
    addBodyClass() {
      document.body.classList.add('is-popup-opened');
    },
    removeBodyClass() {
      document.body.classList.remove('is-popup-opened');
    },
    async logout() {
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = ''; // Очистка содержимого виджета
      }

      this.toggleMobileNav(); // Логика для переключения навигации
      removeToken(); // Удаление токена (например, из localStorage)
      this.user = null; // Обновление состояния пользователя
      this.questionForm = { // Очистка формы
        name: '',
        email: '',
        text: '',
        agree_personal_data: false
      };

      // Ожидание завершения обновления DOM
      await this.$nextTick();

      // Выполняем перенаправление после обновления DOM
      this.$router.push('/');
    },
    async fetchPrizes() {
      try {
        const data = await getWinnings();
        this.prizes = data.results;
      } catch (error) {
        console.error('Error fetching prizes:', error);
      }
    },
    getPrizeTypeText(type) {
      switch (type) {
        case 2:
          return 'Главный';
        case 1:
          return 'Еженедельный';
        case 0:
        default:
          return 'Специальный';
      }
    },
    getPrizeTag(type) {
      return type === 2 ? 'mini-tag--main' : '';
    },
    getPrizeClass(type) {
      switch (type) {
        case 2:
          return 'winners-list-item--main';
        case 1:
          return 'winners-list-item--weekly';
        case 0:
        default:
          return 'winners-list-item--special';
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    formatText(text) {
      // Замена переносов строк на <br>
      let formattedText = text.replace(/\r?\n/g, '<br>');

      // Регулярное выражение для поиска URL
      const urlPattern = /(https?:\/\/[^\s]+)/g;

      // Замена URL на HTML-ссылки
      formattedText = formattedText.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');

      return formattedText;
    },
    clearError(field) {
      this.errors = { ...this.errors, [field]: null };
    },
    redirectToPrivatePage() {
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    redirectToHomePage() {
      this.$router.push('/');
    },
    removeSpacesFirstName() {
      const input = event.target.value.trimStart();
      this.registerForm.first_name = input.length > 0 ? input : '';
    },
    removeSpacesLastName() {
      const input = event.target.value.trimStart();
      this.registerForm.last_name = input.length > 0 ? input : '';
    },
    removeSpacesName() {
      const input = event.target.value.trimStart();
      this.questionForm.name = input.length > 0 ? input : '';
    },
    triggerQrScan() {
      const qrElement = document.getElementById('apm-scan-qr');
      if (qrElement) {
        qrElement.click();
      }
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    openAccountPopup() {
      this.addBodyClass();
      this.AccountPopup = true;
    },
    closeAccountPopup() {
      this.removeBodyClass();
      this.AccountPopup = false;
    },
    openQuestionPopup() {
      this.addBodyClass();
      this.showQuestionPopup = true;
    },
    closeQuestionPopup() {
      this.removeBodyClass();
      this.showQuestionPopup = false;
      this.resetForm();
    },
    openQuestionPopupSuccess() {
      this.addBodyClass();
      this.showQuestionPopupSuccess = true;
    },
    closeQuestionPopupSuccess() {
      this.removeBodyClass();
      this.showQuestionPopupSuccess = false;
      this.resetForm();
    },
    openResetPasswordPopup() {
      this.showResetPasswordPopup = true;
      this.closeLoginPopup();
      this.addBodyClass();
    },
    closeResetPasswordPopup() {
      this.removeBodyClass();
      this.showResetPasswordPopup = false;
      this.resetForm();
    },
    openResetPasswordPopupSuccess() {
      this.addBodyClass();
      this.showResetPasswordSuccess = true;
    },
    closeResetPasswordPopupSuccess() {
      this.removeBodyClass();
      this.showResetPasswordSuccess = false;
      this.resetForm();
    },
    async submitQuestion() {
      try {
        // Валидация формы перед отправкой
        this.feedbackErrors = {};
        if (!this.questionForm.name) {
          this.feedbackErrors.name = 'Введите имя';
        }
        if (!this.questionForm.email) {
          this.feedbackErrors.email = 'Введите email';
        }
        if (!this.questionForm.text) {
          this.feedbackErrors.text = 'Введите сообщение';
        }
        if (!this.questionForm.agree_personal_data) {
          this.feedbackErrors.agree_personal_data = 'Необходимо согласиться с политикой конфиденциальности';
        }

        if (Object.keys(this.feedbackErrors).length > 0) {
          return; // Если есть ошибки валидации, не отправляем запрос
        }

        // Отправка данных формы через API
        await sendFeedback({
          email: this.questionForm.email,
          name: this.questionForm.name,
          text: this.questionForm.text
        });

        // Закрыть попап после успешной отправки
        this.openQuestionPopupSuccess();
        this.$sendMetrik('reachGoal', 'Feedback_SentForm_Success');
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async submitResetPassword() {
      try {
        const { phone_number } = this.resetPasswordForm;
        this.resetPasswordErrors = {};
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g, '')}`;

        // Валидация длины телефонного номера
        if (formattedPhoneNumber.length < 12) {
          this.resetPasswordErrors.phone_number = 'Введите корректный телефон';
          return; // Выходим, если есть ошибка валидации
        }

        // Отправка данных формы через API
        await sendResetPassword({ phone_number: formattedPhoneNumber });

        // Успешная отправка, здесь можно закрыть попап
        this.phoneNumber = formattedPhoneNumber;
        this.closeResetPasswordPopup();
        this.openResetPasswordPopupSuccess();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    resetForm() {
      this.questionForm = {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      };

      this.phone_number = '';
      this.password = '';

      this.registerForm = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      };

      this.resetPasswordForm = {
        phone_number: '',
      };

      this.feedbackErrors = {};
    },
    toggleAccordion(event) {
      event.target.classList.toggle('is-opened');
      const textBlock = event.target.nextElementSibling;
      textBlock.classList.toggle('is-opened');
    },
    handleScroll() {
      this.isHeaderScrolled = window.scrollY > this.scrollThreshold;
    },
    async checkAuthentication() {
      const token = getToken();
      if (token) {
        try {
          const userInfo = await getUserInfo();
          this.user = userInfo;
          // this.questionForm.name = userInfo.first_name;
          // this.questionForm.email = userInfo.email;
        } catch (error) {
          if (error.response && error.response.data) {
            this.responseError = error.response.data.detail;
          }
        }
      }
    },
    async fetchTimerData() {
      try {
        const { start_date, end_date } = await getMainSettings();

        // Текущее время
        const now = new Date();

        // Преобразуем строки дат в объекты Date
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);

        // Увеличиваем дату окончания на 1 день и устанавливаем время на начало следующего дня
        endDate.setUTCDate(endDate.getUTCDate() + 1);
        endDate.setUTCHours(0, 0, 0, 0);

        // Форматируем даты для отображения в локальном времени
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const start = startDate.toLocaleDateString('ru-RU', options);
        const end = endDate.toLocaleDateString('ru-RU', options);
        this.promoDates = `${start} – ${end}`;

        // Проверяем текущее время относительно стартовой и конечной даты
        if (now < startDate) {
          // Если акция еще не началась
          this.calculateTimeDifference(now, startDate);
          this.timerMessage = 'до начала акции';
        } else if (now >= startDate && now < endDate) {
          // Если акция идет в данный момент
          this.calculateTimeDifference(now, endDate);
          this.timerMessage = 'До окончания приема чеков';
        } else {
          // Если акция уже завершилась
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.timerMessage = 'Акция завершилась';
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },

    calculateTimeDifference(start, end) {
      const diff = end - start;

      // Переводим разницу в миллисекундах в дни, часы и минуты
      const totalSeconds = Math.floor(diff / 1000);
      this.days = Math.floor(totalSeconds / (3600 * 24));
      this.hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      this.minutes = Math.floor((totalSeconds % 3600) / 60);
    },
    handleParticipateButton() {
      this.$sendMetrik('reachGoal', 'Check_Click_Yes');
      this.openLoginPopup();
    },
    openLoginPopup() {
      this.addBodyClass();
      this.isMobileNavOpened = false;
      this.showLoginPopup = true;
      this.responseError = '';
    },
    closeLoginPopup() {
      this.removeBodyClass();
      this.showLoginPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopup() {
      this.showRegisterPopup = true;
      this.closeLoginPopup();
      this.responseError = '';
      this.addBodyClass();
    },
    closeRegisterPopup() {
      this.removeBodyClass();
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    closeRegisterFinishPopup() {
      this.addBodyClass();
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopupSuccess() {
      this.$sendMetrik('reachGoal', 'SignUp_SentForm_Success');
      this.addBodyClass();
      this.showRegisterPopupSuccess = true;
      this.responseError = '';
    },
    closeRegisterPopupSuccess() {
      this.removeBodyClass();
      this.showRegisterPopupSuccess = false;
      this.responseError = '';
      this.resetForm();
    },
    async loadFAQ() {
      try {
        const data = await fetchFAQ();
        this.faq = data.results.map(item => ({
          question: item.question,
          answer: item.answer
        }));
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    },
    async login() {
      try {
        const formattedPhoneNumber = `+${this.phone_number.replace(/\D/g,'')}`;
        this.loginFormErrors = {};
        if (formattedPhoneNumber.length < 12) {
          this.loginFormErrors.phone_number = 'Введите корректный телефон';
        }
        if (this.password.length < 2) {
          this.loginFormErrors.password = 'Введите пароль';
        }
        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.loginFormErrors).length > 0) {
          return;
        }

        await authorize(formattedPhoneNumber, this.password);
        setAuthHeader();
        setTimeout(async () => {
          try {
            const userInfo = await getUserInfo();
            if (userInfo) {
              this.userId = userInfo.id;
              this.userUuid = userInfo.uuid;
              this.user = {
                first_name: userInfo.first_name,
                last_name_initial: userInfo.last_name.charAt(0),
              };
              this.questionForm.name = userInfo.first_name;
              this.questionForm.email = userInfo.email;
              this.closeLoginPopup();
              this.removeBodyClass();
              // this.initQrWidget();
              this.$sendMetrik('reachGoal', 'SignIn_SentForm_Success');
              setTimeout(() => {
                this.$router.push('/private');
              }, 500)
            }
          } catch (error) {
            console.error('Error fetching user info', error);
          }
        }, 900); // 500 мс задержка

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async register() {
      try {
        const { first_name, last_name, phone_number, email, agree_personal_data, agree_rules } = this.registerForm;

        // Форматирование номера телефона с добавлением префикса +7
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g,'')}`;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        // Пример простой проверки email на наличие символов @ и .
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email.length > 1 && !emailRegex.test(email)) {
          this.errors.email = 'Введите корректный email';
        }
        // Пример простой проверки телефона по длине
        if (formattedPhoneNumber.length < 12) {
          this.errors.phone_number = 'Введите корректный телефон';
        }
        if (!agree_personal_data) {
          this.errors.agree_personal_data = 'Необходимо согласиться с политикой конфиденциальности';
        }
        if (!agree_rules) {
          this.errors.agree_rules = 'Необходимо согласиться с правилами акции';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на регистрацию с форматированным номером телефона
        await register(first_name, last_name, formattedPhoneNumber, email);

        // Закрываем окно регистрации
        this.closeRegisterFinishPopup();
        this.openRegisterPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    switchToLogin() {
      this.addBodyClass();
      this.showResetPasswordSuccess = false;
      this.showLoginPopup = true;
      this.showRegisterPopup = false;
      this.responseError = '';
    }
  },
};
</script>

<style>
/* Ваши стили */
</style>

<template>
  <div>
    <div :class="['mobile-nav', { 'is-opened': isMobileNavOpened }]">
      <div class="mobile-nav__header">
        <a href="/" @click="redirectToHomePage" class="mobile-logo">
          <img src="@/assets/img/ui/logo.svg" alt="">
        </a>

        <button @click="toggleMobileNav" class="button mobile-button">
          <img src="@/assets/img/icons/close.svg" alt="">
        </button>
      </div>

      <div class="mobile-nav__list">
        <a href="/#how-register" @click="toggleMobileNav">Как принять участие</a>
        <a href="/#prizes" @click="toggleMobileNav">Призы</a>
        <a href="/#winners" @click="toggleMobileNav">Победители</a>
        <a href="/#faq" @click="toggleMobileNav" >FAQ</a>
        <a v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank" @click="handleRulesButtonClick()">Правила акции</a>
      </div>

      <div class="mobile-nav__footer">
        <button v-if="!user" @click="openLoginPopup" class="button button-gradient">
          <span>Войти</span>
        </button>
        <template v-else>
          <button class="button button-gradient" @click="redirectToPrivatePage">
            <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
          </button>
          <button @click="logout" class="button button-logout"></button>
        </template>
      </div>
    </div>

    <header :class="{ 'is-scrolled': isHeaderScrolled }">
      <div class="container">
        <div class="header">
          <div class="header-logo">
            <a href="/" @click="redirectToHomePage">
              <img src="@/assets/img/ui/logo.svg" alt="">
            </a>
          </div>
          <div class="header-nav highlight-text">
            <a href="/#how-register">Как принять участие</a>
            <a href="/#prizes">Призы</a>
            <a href="/#winners">Победители</a>
            <a href="/#faq">FAQ</a>
          </div>
          <div class="header-user">
            <button v-if="!user" @click="openLoginPopup" class="button button-gradient">
              <span>Войти</span>
            </button>
            <template v-else>
              <button class="button button-gradient" @click="redirectToPrivatePage">
                <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
              </button>
              <button @click="logout" class="button button-logout"></button>
            </template>
          </div>
          <div class="mobile-menu">
            <button @click="toggleMobileNav" class="button">
              <img src="@/assets/img/icons/mobile-menu.svg" alt="">
            </button>
          </div>
        </div>
      </div>
    </header>

    <section id="account">
      <div class="container">
        <div class="section-title">
          Личные данные
        </div>
        <div v-for="(section, index) in accountSections" :key="index" class="account-section" :ref="'section-' + index">

          <template v-if="section.component === 'PersonalData'">
            <div class="personal-data">
              <div class="personal-details">
                <div class="personal-details-row">
                  <div class="personal-details__col">
                    <span>Имя</span>
                    <p> {{ accountUser.first_name }} </p>
                  </div>
                  <div class="personal-details__col">
                    <span>Фамилия</span>
                    <p> {{ accountUser.last_name }} </p>
                  </div>
                </div>
                <div class="personal-details-row">
                  <div class="personal-details__col">
                    <span>Email</span>
                    <p> {{ accountUser.email }} </p>
                  </div>
                  <div class="personal-details__col">
                    <span>Номер телефона</span>
                    <p> {{ accountUser.phone_number }} </p>
                  </div>
                </div>
                <div class="personal-details-row">
                  <div class="personal-details__btn">
                    <button class="button button-white button-white--small" @click="openAccountDetailsPopup">
                      <span>Изменить данные</span>
                    </button>

                    <button class="button button-white button-white--small" @click="openAccountChangePasswordPopup">
                      <span>Изменить пароль</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-if="section.component === 'WinningPrizes'">
            <div class="user-prizes">
              <div class="section-title">
                Мои призы
              </div>
              <div v-if="prizes.length > 0" class="user-prizes-list">
                <div v-for="(prize, index) in prizes" :key="index" class="user-prizes-list-item">
                  <div class="user-prizes-list-item__info">
                      <span :class="{'info-block': true, 'is-opened': isOpen[index]}">
                        <button class="info-block__open" @click="openBlock(index)"></button>
                        <span class="info-block__text">
                          <button class="info-block__close" @click="closeBlock(index)"></button>
                          {{ prize.greeting_text }}
                        </span>
                      </span>
                  </div>
                  <div class="user-prizes-list-item__img">
                    <img :src="getImageSrc(prize.cabinet_image)" />
                  </div>
                  <div class="user-prizes-list-item__title">
                    {{ prize.title }}
                  </div>
                </div>
              </div>

              <div v-else class="empty">
                <div class="empty__text">
                  Загружай чеки в период c <span>2 сентября</span> по <span>31 октября 2024 года</span> и выигрывай призы.
                </div>
                <div class="empty__btns">
                  <button class="button button-gradient button-gradient--big" @click="triggerQrScan">
                    <span>Загрузить чек</span>
                  </button>
                </div>
              </div>
            </div>
          </template>

          <template v-if="section.component === 'QuizSection'">
            <div class="users-check">
              <div class="section-title">
                Загруженные чеки
              </div>
              <div class="users-check__btn" v-if="checks.results.length !== 0">
                <button class="button button-gradient button-gradient--big button-gradient--full" @click="triggerQrScan">
                  <span>Загрузить чек</span>
                </button>
              </div>

              <div class="empty" v-if="checks.results.length === 0">
                <div class="empty__text">
                  Вы не загрузили ни одного чека.
                </div>
                <div class="empty__btns">
                  <button class="button button-gradient button-gradient--big" @click="triggerQrScan">
                    <span>Загрузить чек</span>
                  </button>
                </div>
              </div>

              <div class="users-check-list" v-else>
                <div v-for="(check, cIndex) in checks.results" :key="check.id" :class="['check-item', getStatusClass(check.status)]">
                  <div class="check-item__status">
                    {{ getStatusText(check.status) }}
                    <span v-if="check.reject_reason" :class="{'info-block': true, 'is-opened': isOpen[prizes.length + cIndex]}">
                      <button class="info-block__open" @click="openBlock(prizes.length + cIndex)"></button>
                      <span class="info-block__text">
                        <button class="info-block__close" @click="closeBlock(prizes.length + cIndex)"></button>
                        {{ check.reject_reason }}
                      </span>
                    </span>
                  </div>

                  <div class="check-item__info"></div>

                  <div v-if="check.images" class="check-item__img">
                    <a v-for="(image, index) in check.images" :key="index" :href="getImageSrc(image)" :data-fancybox="'user-check-' + cIndex">
                      <img :src="getImageSrc(image)" />
                      <button class="button button-gradient">
                        <span>Открыть</span>
                      </button>
                    </a>
                  </div>

                  <div class="check-item__date">
                    <span>{{ formatDate(check.upload_date) }}</span>
                    <span>{{ formatTime(check.upload_date) }}</span>
                  </div>

                </div>
                <div class="load-more-checks">
                  <button v-if="checks.next"  class="button button-white" @click="loadMoreChecks">
                    <span>Показать ещё</span>
                  </button>
                </div>
              </div>

            </div>
          </template>
        </div>
      </div>
    </section>

    <!-- Popup для изменения данных -->
    <div v-if="showAccountDetailsPopup" class="popup">
      <div class="popup__overlay" @click="closeAccountDetailsPopup"></div>
      <div class="popup__window">
        <button @click="closeAccountDetailsPopup" class="popup__close"></button>
        <div class="popup__title">Изменить данные</div>
        <div class="popup__content">
          <form @submit.prevent="changeAccountDetails" autocomplete="off">
            <!-- Поле Имя -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-name">Имя</label>
                <input type="text" maxlength="255" id="reg-name" v-model="formData.first_name" @input="removeSpacesFirstName" name="first_name"
                       :class="{ 'is-error': errors.first_name }">
                <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
              </div>
            </div>
            <!-- Поле Фамилия -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="last-name">Фамилия</label>
                <input type="text" maxlength="255" id="last-name" v-model="formData.last_name" @input="removeSpacesLastName" name="last_name"
                       :class="{ 'is-error': errors.last_name }">
                <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
              </div>
            </div>
            <!-- Поле Email -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-email">Email</label>
                <input type="text" maxlength="255" id="reg-email" v-model="formData.email" name="email"
                       :class="{ 'is-error': errors.email }">
                <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
              </div>
            </div>
            <!-- Кнопка для отправки формы -->
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-gradient button-gradient--big button-gradient--full">
                  <span>Изменить данные</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showAccountDetailsSuccess" class="popup">
      <div class="popup__overlay" @click="closeAccountDetailsSuccess"></div>
      <div class="popup__window">
        <button @click="closeAccountDetailsSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Ваши данные успешно обновлены
          </div>
        </div>
      </div>
    </div>

    <!-- Popup для изменения пароля -->
    <div v-if="showAccountChangePasswordPopup" class="popup">
      <div class="popup__overlay" @click="closeAccountChangePasswordPopup"></div>
      <div class="popup__window">
        <button @click="closeAccountChangePasswordPopup" class="popup__close"></button>
        <div class="popup__title">Изменить пароль</div>
        <div class="popup__content">
          <form @submit.prevent="changeAccountPassword" autocomplete="off">
            <!-- Поле пароля -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="password">Пароль</label>
                <input
                    :type="passwordVisible ? 'text' : 'password'"
                    maxlength="255"
                    id="password"
                    v-model="password"
                    name="password"
                />
                <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password }}<br></span>
              </div>
            </div>
            <!-- Поле подтверждения пароля -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="password">Подтверждение пароля</label>
                <input
                    :type="passwordVisible ? 'text' : 'password'"
                    maxlength="255"
                    id="password_check"
                    v-model="password_check"
                    name="password"
                />
                <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password_check }}<br></span>
              </div>
            </div>
            <!-- Кнопка для отправки формы регистрации -->
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-gradient button-gradient--big button-gradient--full"
                        :disabled="!isFormValid">
                  <span>Изменить пароль</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showAccountChangePasswordSuccess" class="popup">
      <div class="popup__overlay" @click="closeAccountChangePasswordSuccess"></div>
      <div class="popup__window">
        <button @click="closeAccountChangePasswordSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Ваш пароль успешно обновлён
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global qrWidget */
import {
  getMainSettings,
  getToken,
  getUserInfo,
  changeProfile,
  setAuthHeader,
  getWinningPrizes,
  getUserChecks,
  changePassword, removeToken
} from "@/api";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import metrikaMixin from "@/mixins/metrikaMixin";

export default {
  name: 'PrivatePage',
  mixins: [metrikaMixin],
  data() {
    return {
      isHeaderScrolled: false,
      scrollThreshold: 0,
      isMobileNavOpened: false,
      formData: {
        first_name: '',
        last_name: '',
        email: ''
      },
      user: null,
      password: '',
      password_check: '',
      loginFormErrors: {},
      passwordVisible: false,
      rootUrl: window.location.origin,
      prizes: [],
      isOpen: [],
      errors: {},
      showAccountChangePasswordPopup: false,
      showAccountDetailsPopup: false,
      showAccountDetailsSuccess: false,
      showAccountChangePasswordSuccess: false,
      mainSettings: {},
      activeAccountSection: 0,
      scrolledAccountSection: -1,
      accountUser: {},
      responseError: '',
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      accountDetailsForm: {
        first_name: '',
        last_name: '',
        email: '',
      },
      checks: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      checkOffset: 0,
      checkLimit: 20,
      statusTextMap: {
        0: 'Загружен',
        1: 'На проверке',
        3: 'Одобрен',
        4: 'Отклонен',
        5: 'Выиграл',
      },
      accountSections: [
        { name: 'Личные данные', component: 'PersonalData', scrolled: false },
        { name: 'Выигрынные призы', component: 'WinningPrizes', scrolled: false},
        { name: 'Загруженные чеки', component: 'QuizSection', scrolled: false },
      ],
    }
  },
  mounted() {
    document.documentElement.style.setProperty('--text-accent-1-1', 'var(--desired-color)');
    this.updateColor();
    this.formData = {
      first_name: this.accountUser ? this.accountUser.first_name : '',
      last_name: this.accountUser ? this.accountUser.last_name : '',
      email: this.accountUser ? this.accountUser.email : ''
    };
    Fancybox.bind("[data-fancybox]", {
    });
    this.initializeOpenState();
    this.fetchWinningPrizes();
    this.fetchUsersCheck();
    setAuthHeader();
    const token = getToken();
    if (token) {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.accountUser = {
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            phone_number: userInfo.phone_number,
            email: userInfo.email,
          };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
          this.userId = userInfo.id;
          this.userUuid = userInfo.uuid;
          this.user = {
            first_name: userInfo.first_name,
            last_name_initial: userInfo.last_name.charAt(0),
          };
          this.initQrWidget();
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    isPrivatePage() {
      return this.$route.path === '/private';
    },
    isFormValid() {
      return (
          this.password.length >= 6 &&
          this.password_check.length >= 6 &&
          this.password === this.password_check
      );
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    initQrWidget() {
      if (!document.getElementById('apm-scan-qr')) {
        qrWidget.init('apm-widget', {
          api: 'https://api.apmcheck.ru',
          apiKey: 'ab6bd3e6-c19d-4104-8056-1a83b0cc32e6',
          userUuid: this.userUuid,
          callbacks: {
            onReceiptSentSuccess: () => {
              this.$sendMetrik('reachGoal', 'Check_Download_Success');
            }
          }
        });
      }
    },
    redirectToPrivatePage() {
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    handleRulesButtonClick() {
      this.$sendMetrik('reachGoal', 'Rules_Click');
    },
    async logout() {
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = ''; // Очистка содержимого виджета
      }

      this.toggleMobileNav(); // Логика для переключения навигации
      removeToken(); // Удаление токена (например, из localStorage)
      this.user = null; // Обновление состояния пользователя
      this.questionForm = { // Очистка формы
        name: '',
        email: '',
        text: '',
        agree_personal_data: false
      };

      // Ожидание завершения обновления DOM
      await this.$nextTick();

      // Выполняем перенаправление после обновления DOM
      this.$router.push('/');
    },
    openLoginPopup() {
      this.addBodyClass();
      this.isMobileNavOpened = false;
      this.showLoginPopup = true;
      this.responseError = '';
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    redirectToHomePage() {
      this.$router.push('/');
    },
    addBodyClass() {
      document.body.classList.add('is-popup-opened');
    },
    removeBodyClass() {
      document.body.classList.remove('is-popup-opened');
    },
    updateColor() {
      const event = new CustomEvent('colorChange', {
        detail: 'var(--text-accent-1-1)',
      });
      window.dispatchEvent(event);
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    loadMoreChecks() {
      if (this.checks.next) {
        this.checkOffset += 20;
        this.fetchUsersCheck();
      }
    },
    goToGamePage() {
      this.$router.push('/game');
      setTimeout(function(){
        window.scrollTo({
          top: '0',
          behavior: 'smooth'
        });
      }, 200)
    },
    initializeOpenState() {
      this.isOpen = [...this.prizes, ...this.checks.results].map(() => false);
    },
    openBlock(index) {
      this.$nextTick(() => {
        this.isOpen[index] = true; // Установка состояния для открытия блока
      });
    },
    closeBlock(index) {
      this.$nextTick(() => {
        this.isOpen[index] = false; // Установка состояния для закрытия блока
      });
    },
    getImageSrc(imageUrl) {
      // You can add any logic here to manipulate or format the image URL if needed
      return imageUrl;
    },
    getStatusText(status) {
      return this.statusTextMap[status] || 'Unknown';
    },
    getStatusClass(status) {
      return {
        'status-loaded': status === 0,
        'status-pending': status === 1,
        'status-approved': status === 3,
        'status-rejected': status === 4,
        'status-won': status === 5,
      };
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    formatTime(dateString) {
      const date = new Date(dateString);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    triggerQrScan() {
      const qrElement = document.getElementById('apm-scan-qr');
      if (qrElement) {
        qrElement.click();
      }
    },
    async fetchWinningPrizes() {
      try {
        const prizes = await getWinningPrizes();
        this.prizes = prizes.results;
      } catch (error) {
        console.error('Error fetching winning prizes:', error);
      }
    },
    async fetchUsersCheck() {
      try {
        const checksData = await getUserChecks(this.checkOffset, this.checkLimit);

        // Добавляем новые чеки к существующим
        this.checks.results.push(...checksData.results);

        // Обновляем значение next
        this.checks.next = checksData.next;

      } catch (error) {
        console.error('Error fetching checks:', error);
      }
    },
    removeSpacesFirstName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.accountUser.first_name = input;
    },
    removeSpacesLastName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.accountUser.last_name = input;
    },
    openAccountDetailsPopup() {
      this.addBodyClass();
      this.formData = {
        first_name: this.accountUser ? this.accountUser.first_name : '',
        last_name: this.accountUser ? this.accountUser.last_name : '',
        email: this.accountUser ? this.accountUser.email : ''
      };
      this.showAccountDetailsPopup = true;
      this.responseError = '';
    },
    openAccountChangePasswordPopup() {
      this.addBodyClass();
      this.showAccountChangePasswordPopup = true;
      this.responseError = '';
    },
    closeAccountDetailsPopup() {
      this.removeBodyClass();
      this.showAccountDetailsPopup = false;
    },
    closeAccountChangePasswordPopup() {
      this.removeBodyClass();
      this.showAccountChangePasswordPopup = false;
    },
    openAccountDetailsPopupSuccess() {
      this.addBodyClass();
      this.showAccountDetailsSuccess = true;
      this.responseError = '';
    },
    openAccountChangePasswordPopupSuccess() {
      this.addBodyClass();
      this.showAccountChangePasswordSuccess = true;
      this.responseError = '';
    },
    closeAccountDetailsSuccess() {
      this.removeBodyClass();
      this.showAccountDetailsSuccess = false;
    },
    closeAccountChangePasswordSuccess() {
      this.removeBodyClass();
      this.showAccountChangePasswordSuccess = false;
    },
    async changeAccountPassword() {
      try {
        const { password, password_check } = this;

        // Проверка полей
        this.errors = {};
        if (password.length < 6) {
          this.errors.password = 'Пароль должен быть не менее 6 символов';
        }
        if (password_check.length < 6) {
          this.errors.password_check = 'Подтверждение пароля должно быть не менее 6 символов';
        }
        if (password !== password_check) {
          this.errors.password_check = 'Пароли не совпадают';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на изменение пароля
        await changePassword(password);

        // Успешное завершение действий, например, закрытие модального окна или уведомление
        this.closeAccountChangePasswordPopup();
        this.openAccountChangePasswordPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async changeAccountDetails() {
      try {
        const { first_name, last_name, email } = this.formData;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        // Пример простой проверки email на наличие символов @ и .
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email.length > 1 && !emailRegex.test(email)) {
          this.errors.email = 'Введите корректный email';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на изменение данных
        await changeProfile(first_name, last_name, email);

        // Обновляем accountUser только после успешной отправки
        this.accountUser = { ...this.formData };

        // Закрываем окно регистрации и показываем успешное сообщение
        this.closeAccountDetailsPopup();
        this.openAccountDetailsPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    handleScroll() {
      this.isHeaderScrolled = window.scrollY > this.scrollThreshold;
    },
  },
  async created() {
    try {
      const [mainSettingsData] = await Promise.all([
        getMainSettings()
      ]);
      this.mainSettings = mainSettingsData;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
};
</script>